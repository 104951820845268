import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IContactDepartment } from '../../services/models/contact.model';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { environment } from '../../../environments/environment';
import { EmailService } from '../../services/email.service';
import { AlertService } from '../../services/alert.service';
import { WebsiteService } from '../../services/website.service';
import { ApiService } from '../../services/api.service';
import { ICompanyOverview } from '../../services/models/member.model';
import { IDictionary, IEmailRequest } from '../../services/models/email.model';
import { LoaderComponent } from '../../shared/components/loader/loader.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [RouterOutlet, ReactiveFormsModule, LoaderComponent, NgClass, NgForOf, NgIf],
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  // Head office Variables
  headOfficeForm!: UntypedFormGroup;
  isHQContact: boolean = true;
  departments: IContactDepartment[] = [];
  disableDepartmentSelect: boolean = false;
  loadingDepartments: boolean = false;

  // Member variables
  memberForm!: UntypedFormGroup;
  memberParamID: number | null = null;
  members: ICompanyOverview[] = [];
  disableMemberSelect: boolean = false;
  loadingMembers: boolean = false;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  env = environment;
  sending: boolean = false;

  constructor(private apiService: ApiService,
              private websiteService: WebsiteService,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private emailService: EmailService) {
  }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.route.params.subscribe((param) => {
      if (param['companyID']) {
        this.memberParamID = +param['companyID'];
        this.isHQContact = false;
      }
    });

    this.headOfficeForm = new UntypedFormGroup({
      FullName: new UntypedFormControl(null, [Validators.required]),
      Email: new UntypedFormControl(null, [Validators.required]),
      Department: new UntypedFormControl(null, [Validators.required]),
      MessageText: new UntypedFormControl(null, [Validators.required])
    });

    this.memberForm = new UntypedFormGroup({
      FullName: new UntypedFormControl(null, [Validators.required]),
      Email: new UntypedFormControl(null, [Validators.required]),
      Member: new UntypedFormControl(null, [Validators.required]),
      MessageText: new UntypedFormControl(null, [Validators.required])
    });

    this.loadDepartments();
    this.loadMembers();
  }

  ngOnDestroy() {
    this.websiteService.MemberToContact = null;
    this.websiteService.EmployeeToContact = null;
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  // Head Office //

  loadDepartments() {
    this.loadingDepartments = true;

    if (this.websiteService.EmployeeToContact) {
      this.checkEmployeeContact();
      this.loadingDepartments = false;
    } else {
      this.apiService.get('Contact/GetDepartmentData').pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
        if (data && data.length > 0) {
          this.departments.push(...data);
          this.headOfficeForm?.controls['Department'].enable();
          this.checkEmployeeContact();
        }
        this.loadingDepartments = false;
      });
    }
  }

  checkEmployeeContact() {
    if (this.websiteService.EmployeeToContact) {
      const employee = {
        Address: this.websiteService.EmployeeToContact.Email,
        Name: this.websiteService.EmployeeToContact.FullName
      } as IContactDepartment;
      this.departments.push(employee);
      this.headOfficeForm?.controls['Department'].setValue(employee);
      this.headOfficeForm?.controls['Department'].markAsTouched();
      this.disableDepartmentSelect = true;
      this.isHQContact = true;
    }
  }

  sendHQContactRequest() {
    this.headOfficeForm?.markAllAsTouched();
    this.headOfficeForm?.markAsTouched();

    if (this.headOfficeForm?.valid) {
      this.sending = true;

      const body: IDictionary<string> = {};
      body['FullName'] = this.headOfficeForm?.controls['FullName'].value;
      body['Message'] = this.headOfficeForm?.controls['MessageText'].value;
      body['Email'] = this.headOfficeForm?.controls['Email'].value;
      body['Title'] = `Contact request for ${this.headOfficeForm?.controls['Department'].value.Name} department`;
      body['Department'] = this.headOfficeForm?.controls['Department'].value.Name;

      const email = {
        Subject: 'Contact Request via Harmony Website',
        ToAddress: this.headOfficeForm.controls['Department'].value.Address, // change this when testing
        Template: 'Web Contact',
        ScheduleID: null,
        Body: body,
        Attachments: null
      } as IEmailRequest;

      this.sendEmail(email, this.headOfficeForm?.controls['Email'].value);
      this.resetHeadOfficeForm();
    }
  }

  resetHeadOfficeForm() {
    let defaultDep = null;
    if (this.websiteService.EmployeeToContact) {
      defaultDep = {
        Address: this.websiteService.EmployeeToContact.Email,
        Name: this.websiteService.EmployeeToContact.FullName
      } as IContactDepartment;
    }
    this.headOfficeForm?.reset(new UntypedFormGroup({
      FullName: new UntypedFormControl(null, [Validators.required]),
      Email: new UntypedFormControl(null, [Validators.required]),
      Department: new UntypedFormControl(defaultDep, [Validators.required]),
      MessageText: new UntypedFormControl(null, [Validators.required])
    }));
    this.websiteService.EmployeeToContact = null;
  }

  // Members //
  loadMembers() {
    this.loadingMembers = true;

    this.members = [];
    this.memberForm?.controls['Member'].setValue(null);

    if (this.websiteService.NetworkMapData && this.websiteService.NetworkMapData.length > 0) {
      this.members.push(...this.websiteService.NetworkMapData);
      this.checkMemberContact();
      this.loadingMembers = false;
    } else {
      this.apiService.get('Map/GetData').pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data: ICompanyOverview[]) => {
        if (data) {
          const filtered = this.getCorporateFilteredList(data);
          this.members.push(...filtered);
          this.websiteService.NetworkMapData = this.members;
          this.checkMemberContact();
        }
        this.loadingMembers = false;
      });
    }
  }

  getCorporateFilteredList(companyData: ICompanyOverview[]) {
    return [...companyData.filter(x => x.Country != 'Russian Federation')]
  }

  orderedMembers(): ICompanyOverview[] {
    return this.members.sort((a, b) => a.Name < b.Name ? -1 : 1);
  }

  checkMemberContact() {
    if (this.memberParamID) {
      const member = this.members.find((x) => x.CompanyID === this.memberParamID);
      if (member) {
        this.members.push(member);
        this.memberForm?.controls['Member'].setValue(member);
        this.isHQContact = false;
        this.disableMemberSelect = true;
      }
    } else {
      if (this.websiteService.MemberToContact) {
        const member = this.members.find((x) => x.CompanyID === this.websiteService.MemberToContact?.CompanyID);
        if (member) {
          this.members.push(member);
          this.memberForm?.controls['Member'].setValue(member);
          this.isHQContact = false;
          this.disableMemberSelect = true;
        }
      }
    }
  }

  sendMemberContactRequest() {
    this.memberForm?.markAllAsTouched();
    this.memberForm?.markAsTouched();

    const body: IDictionary<string> = {};
    body['FullName'] = this.memberForm?.controls['FullName'].value;
    body['Message'] = this.memberForm?.controls['MessageText'].value;
    body['Email'] = this.memberForm?.controls['Email'].value;
    body['Title'] = `Contact request for ${this.memberForm?.controls['Member'].value.Name}`;

    if (this.memberForm?.valid) {
      this.sending = true;

      const email = {
        Subject: 'Contact Request via Harmony Website',
        ToAddress: this.memberForm.controls['Member'].value.MemberEmail, // change this when testing
        Template: 'Web Contact',
        ScheduleID: null,
        Body: body,
        Attachments: null
      } as IEmailRequest;

      this.sendEmail(email, this.memberForm?.controls['Email'].value);
      this.resetMemberForm();
    }
  }

  resetMemberForm() {
    this.memberForm?.reset(new UntypedFormGroup({
      FullName: new UntypedFormControl(null, [Validators.required]),
      Email: new UntypedFormControl(null, [Validators.required]),
      Member: new UntypedFormControl(null, [Validators.required]),
      MessageText: new UntypedFormControl(null, [Validators.required])
    }));
    this.disableMemberSelect = false;
    this.websiteService.MemberToContact = null;
  }

  sendEmail(email: IEmailRequest, confirmationAddress: string) {
    this.emailService.sendEmail(email)
      .subscribe({
        next: (data) => {
          this.sending = true;
          if (data) {
            this.alertService.info('Email successfully sent.');
            this.sendContactConfirmation(confirmationAddress);
          }
          this.sending = false;
        }, error: () => {
          this.sending = false;
          this.alertService.error('Could not send email. Please try again later.');
        }
      });
  }

  sendContactConfirmation(confirmationAddress: string) {
    const email = {
      Subject: 'Contact Request via Harmony Website Received',
      ToAddress: confirmationAddress,
      Template: 'Contact Confirmation',
      ScheduleID: null,
      Body: {},
      Attachments: null
    } as IEmailRequest;

    this.emailService.sendEmail(email)
      .subscribe({
        next: (data) => {
          this.sending = true;
          if (data) {
            this.alertService.info('Confirmation successfully sent.');
          }
          this.sending = false;
        }, error: () => {
          this.sending = false;
          this.alertService.error('Could not send confirmation email.');
        }
      });
  }
}
