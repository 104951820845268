export class MapStyles {
  static readonly MemberMap = [
    {
      elementType: 'geometry',
      stylers: [{color: '#878787'}]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{color: '#EEEDE8'}]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{color: '#4b4b4b'}]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{color: '#4b4b4b'}]
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{color: '#4b4b4b'}]
    },
    {
      featureType: 'poi.business',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{color: '#5FC787'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{color: '#D93EAC'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{color: '#EEEDE8'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{color: '#EEEDE8'}]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9ca5b3'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#bab5b4'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{color: '#afafaf'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{color: '#afafaf'}]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{color: '#444444'}]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{color: '#a22b9d'}]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{color: '#F5F5F5'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{color: '#515c6d'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{color: '#515c6d'}]
    }
  ];

  static readonly DirectQuote = [
    {
      featureType: 'poi.business',
      stylers: [{visibility: 'off'}]
    },
  ] as google.maps.MapTypeStyle[];
}
