<div class="history-wrapper">
  <div class="history-content">
    <div class="row">
      <div class="col-md-12">
        <h1 class="harmony-blue-text fw-bold">
          History
        </h1>
      </div>
    </div>
    <div class="row harmony-grey-text">
      <div class="col-md-12">
        <p>
          Harmony Relocation Network was established in 1992, with an initial focus on Europe and the USA.
          Since the late 1990s, Harmony has grown to include member companies from every corner of the globe.
          Our members are independent, market leaders in their regions and together, we have grown into the strong and
          reliable network that we are today. But the story doesn't end here, as we continue to expand our network
          to serve our customers moving around the world.
        </p>
      </div>
    </div>
    <div class="row m-1 mt-4 dyk">
      <div class="col-12 text-center">
        <img class="dyk-background" src="../../../../assets/shapes/blue/long-hex-horizontal.svg" alt=""/>
        <h4 class="dyk-header">
          Did You Know?
        </h4>
        <div class="dyk-text">
          <h3>Harmony</h3> has the same meaning in 43 languages, spoken in 150 countries
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <h1 class="harmony-blue-text fw-bold">
          Our Name
        </h1>
      </div>
    </div>
    <div class="row mb-5 harmony-grey-text">
      <div class="col-md-12">
        <p>
          Our network is made up of family owned companies who are locally rooted and who are all experts in providing
          moving and relocation services. They value personal, long-term relationships and strive to provide a superior
          quality of service to their customers. The word Harmony embraces our core value; we genuinely care for people.
        </p>
      </div>
    </div>
  </div>
  <div class="history-images">
    <img class="history-image image-1" src="../../../../assets/images/shaped/diamond-building-1.png" alt=""/>
    <img class="history-image image-2" src="../../../../assets/shapes/teal/hex-sm.svg" alt=""/>
    <img class="history-image image-3" src="../../../../assets/images/shaped/diamond-nature-1.png" alt=""/>
  </div>
</div>
