import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-joining-our-network',
  standalone: true,
  templateUrl: './joining-our-network.component.html',
  imports: [
    RouterLink
  ],
  styleUrls: ['./joining-our-network.component.scss']
})
export class JoiningOurNetworkComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
