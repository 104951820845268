import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ICompanySearchResult } from '../../services/models/member.model';
import { WebsiteService } from '../../services/website.service';
import { environment } from '../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  imports: [
    FormsModule,
    FaIconComponent,
    RouterLink,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  // Icons
  faSearch = faSearch;

  // Component Variables
  searchText: string | null = null;
  searchResults: ICompanySearchResult[] | null = [];
  searchInFocus: boolean = false;
  env = environment;

  constructor(public router: Router,
              private websiteService: WebsiteService) { }

  ngOnInit() { }

  onPrimarySearch() {
    if (this.searchText && this.searchText.length > 0) {
      this.websiteService.SearchWebsite(this.searchText).subscribe((results: ICompanySearchResult[]) => {
        if (results && results.length) {
          this.searchResults = Object.assign([], results);
        } else {
          this.searchResults = null;
        }
      });
    } else {
      this.searchResults = null;
      this.searchText = null;
    }
  }

  showResults() {
    const hasResults = this.searchText && this.searchResults && this.searchResults.length > 0;
    return hasResults && this.searchInFocus;
  }
}
