import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import {
  IDataSource, IEmail,
  IEmailRequest, IEmailSchedule,
  IEmailScheduleAttachmentDto, IEmailScheduleDto,
  IEmailScheduleSearch,
  IEmailSearch, IEmailStatus, IEmailTemplate, IRecurrenceType
} from './models/email.model';
import { IPaginationData } from '../shared/models/pagination-data.model';
import { IOrderParam } from '../shared/directives/order/order.directive';

@Injectable({
  providedIn: 'root',
})
export class EmailService implements OnDestroy {

  dataSources$: BehaviorSubject<IDataSource[]> = new BehaviorSubject<IDataSource[]>([]);
  recurrenceTypes$: BehaviorSubject<IRecurrenceType[]> = new BehaviorSubject<IRecurrenceType[]>([]);
  emailTemplates$: BehaviorSubject<IEmailTemplate[]> = new BehaviorSubject<IEmailTemplate[]>([]);
  emailStatuses$: BehaviorSubject<IEmailStatus[]> = new BehaviorSubject<IEmailStatus[]>([]);
  emailSchedules$: BehaviorSubject<IEmailSchedule[]> = new BehaviorSubject<IEmailSchedule[]>([]);

  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) {
    this.loadLookupData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadLookupData() {
    // Data Sources
    this.apiService.get('DataSource/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IDataSource[]) => {
      if (data) {
        this.dataSources$.next(data);
      }
    });

    // Recurrence Types
    this.apiService.get('RecurrenceType/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IRecurrenceType[]) => {
      if (data) {
        this.recurrenceTypes$.next(data);
      }
    });

    // Email Templates
    this.apiService.get('EmailTemplate/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IEmailTemplate[]) => {
      if (data) {
        this.emailTemplates$.next(data);
      }
    });

    // Email Statuses
    this.apiService.get('EmailStatus/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IEmailStatus[]) => {
      if (data) {
        this.emailStatuses$.next(data);
      }
    });

    // Email Schedules
    this.apiService.get('EmailSchedule/GetAll').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: IEmailSchedule[]) => {
      if (data) {
        this.emailSchedules$.next(data);
      }
    });
  }

  sendEmail(email: IEmailRequest) {
    const formData = new FormData();
    formData.append('Subject', email.Subject);
    formData.append('ToAddress', email.ToAddress);
    formData.append('Template', email.Template);

    if (email.Body) {
      // tslint:disable-next-line:forin
      for (const key in email.Body) {
        const value = email.Body[key];
        formData.append(`Body[${key}]` , value);
      }
    }

    return this.apiService.post('Email/Send', formData).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getEmail(id: number) {
    return this.apiService.get('Email/' + id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getEmailContent(id: number) {
    return this.apiService.get('Email/Content/' + id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  searchEmails(params: IEmailSearch, o: IOrderParam): Observable<IPaginationData<IEmail>> {
    return this.apiService.post(`Email/Search?orderBy=${o.OrderBy}&order=${o.OrderDirection}`, params).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  createEmailSchedule(emailSchedule: IEmailScheduleDto) {
    return this.apiService.post('EmailSchedule/Create', emailSchedule).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getEmailSchedule(id: number) {
    return this.apiService.get('EmailSchedule/' + id).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  searchEmailSchedule(params: IEmailScheduleSearch, o: IOrderParam) {
    return this.apiService.post(`EmailSchedule/Search?orderBy=${o.OrderBy}&order=${o.OrderDirection}`, params).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  updateEmailSchedule(emailSchedule: IEmailScheduleDto) {
    return this.apiService.put('EmailSchedule/Update', emailSchedule).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  addScheduleAttachment(scheduleAttachment: IEmailScheduleAttachmentDto) {
    const formData = new FormData();
    formData.append('EmailScheduleId', scheduleAttachment.EmailScheduleId.toString());
    formData.append('Name', scheduleAttachment.File.name);
    formData.append('File', scheduleAttachment.File, scheduleAttachment.File.name);

    return this.apiService.post('EmailSchedule/AddAttachment', formData).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteScheduleAttachment(scheduleAttachmentID: number) {
    return this.apiService.delete('EmailSchedule/DeleteAttachment/' + scheduleAttachmentID).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  runEmailSchedule(scheduleID: number) {
    return this.apiService.get('EmailSchedule/Execute/' + scheduleID).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
