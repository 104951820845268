import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';
import { HistoryComponent } from './history/history.component';
import { ClientsComponent } from './clients/clients.component';
import { ServicesComponent } from './services/services.component';
import { PeopleComponent } from './people/people.component';
import { CommitmentComponent } from './commitment/commitment.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [RouterOutlet, HistoryComponent, ClientsComponent, ServicesComponent, PeopleComponent, CommitmentComponent, NgIf],
  templateUrl: 'about-us.component.html',
  styleUrls: ['about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  // Component Variables
  section: string = '';

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      this.section = params['section'];
    });
  }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
