import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class FileService implements OnDestroy {
  // Service Variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public logger = (this as any).constructor.name as string;

  constructor(private api: ApiService,
              private sanitizer: DomSanitizer,
              private log: LogService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public GetFile(containerName: string, folderAndFileName: string, downloadName?: string) {
    // Get file data and download file
    this.api.postFile(`File/GetFile`, this.getRequestBody(containerName, folderAndFileName))
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data) => {
      const filename = this.GetFileName(data);
      if (downloadName) {
        this.DownloadFile(data, downloadName);
      } else {
        if (filename) {
          this.DownloadFile(data, filename);
        }
      }
    });
  }

  public GetFileBlobUrl(containerName: string, folderAndFileName: string) {
    let subject = new Subject<SafeUrl>();
    this.api.postFile(`File/GetFile`, this.getRequestBody(containerName, folderAndFileName)).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (response) => {
        const url = URL.createObjectURL(this.initializeBlob(response));
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        subject.next(sanitizedUrl);
      },
      error: (err) => {
        this.log.error('Failed to retrieve blob', this.logger, 'GetFileBlobUrl', err);
      }
    });
    return subject.asObservable();
  }

  // Get a valid token to access a containers items.
  public GetSasToken(containerName: string) {
    return this.api.get(`File/Token/${containerName}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public GetFileName(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('content-disposition');

    return contentDisposition != null ?
      contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replaceAll('"', '') :
      null;
  }

  public DeleteFile(containerName: string, blobName: string) {
    return this.api.delete(`File/DeleteFile?container=${containerName}&blob=${blobName}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }


  public DownloadFile(response: HttpResponse<Blob>, docName: string) {
    const url = window.URL.createObjectURL(this.initializeBlob(response));

    // Create anchor element to trigger download
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = url;
    a.download = docName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  private getRequestBody(containerName: string, folderAndFileName: string) {
    return {
      ContainerName: containerName,
      BlobName: folderAndFileName
    };
  }

  private initializeBlob(response: HttpResponse<Blob>) {
    const type = response?.body?.type;
    return new Blob([response?.body!], {type});
  }
}
