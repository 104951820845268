import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {ApiService} from "./api.service";
import {takeUntil} from "rxjs/operators";
import {IPage} from "../shared/models/pagination-data.model";
import {IOrderParam} from "../shared/directives/order/order.directive";
import { IArticleSearchParam } from './models/articles.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleService implements OnDestroy {

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) { }

  ngOnDestroy(): void {
      this.unsubscribe.next(null);
      this.unsubscribe.complete();
  }

  GetArticles(page: IPage, order: IOrderParam, param: IArticleSearchParam) {
    return this.apiService.post(`Article/Search?pageNumber=${page.pageNumber}&pageSize=${page.pageSize}&orderBy=${order.OrderBy}&order=${order.OrderDirection}`, param)
      .pipe(takeUntil(this.unsubscribe))
  }

  get(id: number) {
    return this.apiService.get(`Article/${id}`)
        .pipe(takeUntil(this.unsubscribe))
  }

  getAttachments(articleId: number) {
    return this.apiService.get(`ArticleAttachment/${articleId}`)
      .pipe(takeUntil(this.unsubscribe))
  }
}
