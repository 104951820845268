<div class="row">
  <div class="col-12">
    <input id="{{identifier}}"
           appAutocompleteOff
           class="form-control form-control-sm date-input"
           placeholder="{{placeholder}}"
           type="text"
           ngbDatepicker
           #identifier="ngbDatepicker"
           [ngClass]="{'is-invalid': showError}"
           [value]="currentValue"
           (change)="toNgbDate($event)"
           [maxDate]="maxDate"
           [minDate]="minDate"
           (dateSelect)="setDate($event)"
           [disabled]="disabled"
           [title]="title"/>
    <button class="btn btn-sm btn-primary harmony-purple-button date-button"
            [title]="title" (click)="identifier.toggle()"
            [disabled]="disabled">
      <fa-icon [icon]="faCalendar"></fa-icon>
    </button>
  </div>
</div>
