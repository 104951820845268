export interface ILocation {
  Latitude: number;
  Longitude: number;
}

export class RegionCoordinates {
  public NorthAmerica = {
    lat: 45,
    lng: -100
  };

  public SouthAmerica = {
    lat: -9,
    lng: -55
  };

  public MiddleEast = {
    lat: 29,
    lng: 42
  };

  public Africa = {
    lat: 2,
    lng: 34
  };

  public Europe = {
    lat: 50,
    lng: 15
  };

  public Oceania = {
    lat: -23,
    lng: 140
  };

  public Asia = {
    lat: 34,
    lng: 100
  };
}
