import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-portal-redirect',
  standalone: true,
  imports: [],
  templateUrl: './portal-redirect.component.html',
  styleUrl: './portal-redirect.component.scss'
})
export class PortalRedirectComponent {
  private env = environment;

  navigateToPortal() {
    window.open(this.env.portal_url, "_blank");
  }
}
