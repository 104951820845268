import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private api: ApiService) {
  }

  info(message: string, logger: string, methodName: string,  properties: object) {
    this.api.post('Log/Info', this.createBody(message, logger, methodName, properties))
      .subscribe();
  }

  warn(message: string, logger: string, methodName: string, properties: object) {
    this.api.post('Log/Warn', this.createBody(message, logger, methodName, properties))
      .subscribe();
  }

  error(message: string, logger: string, methodName: string, properties: object) {
    this.api.post('Log/Error', this.createBody(message, logger, methodName, properties))
      .subscribe();
  }

  private createBody(message: string, logger: string, methodName: string, properties: object) {
    const body = {
      Message: message,
      Logger: `${logger} - ${methodName}`,
      Properties: properties,
      Source: 'Portal'
    };
    return body;
  }
}
