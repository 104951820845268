import { Component, Input, OnInit } from '@angular/core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { IEmployee } from '../../../services/models/contact.model';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { WebsiteService } from '../../../services/website.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-employee-card',
  standalone: true,
  templateUrl: 'employee-card.component.html',
  imports: [
    FaIconComponent
  ],
  styleUrls: ['employee-card.component.scss']
})
export class EmployeeCardComponent implements OnInit {
  @Input() Employee: IEmployee | null = null;

  // Icons
  faEnvelope = faEnvelope;

  // Component variables
  env = environment;

  constructor(private websiteService: WebsiteService,
              private router: Router) { }

  ngOnInit() { }

  contactEmployee(employee: IEmployee | null) {
    this.websiteService.EmployeeToContact = employee;
    this.router.navigate(['contact']).then(r => {});
  }
}
