import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ILocation } from '../../../services/models/member-map.model';
import { environment } from '../../../../environments/environment';
import { WebsiteService } from '../../../services/website.service';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  public env = environment;

  constructor(private websiteService: WebsiteService,
              private router: Router) { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  findNearbyClient() {
    this.websiteService.GetUserPosition().then((pos) => {
      this.websiteService.CurrentUserLocation = { Latitude: pos.lat, Longitude: pos.lng } as ILocation;
      this.router.navigate(['global-network']).catch();
    });
  }
}
