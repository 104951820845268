import { Component } from '@angular/core';
import { faAngleRight, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, FaIconComponent],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  // Icons
  faScrollToTop = faArrowAltCircleUp;
  faArrowRight = faAngleRight;

  // Variables
  codeOfConductUrl: string;
  env = environment;

  constructor() {
    this.codeOfConductUrl = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.ContainerNames.WebDocuments}/${this.env.Blobs.CodeOfConduct}`;
  }

  goToPortal() {
    window.open(this.env.portal_url, "_blank");
  }

  scrollToTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  getYear(){
    return new Date().getFullYear();
  }
}
