import { Component, OnInit } from '@angular/core';
import { RegionCoordinates } from '../../services/models/member-map.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICompanyOverview } from '../../services/models/member.model';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MemberMapComponent } from '../../shared/components/member-map/member-map.component';

@Component({
  selector: 'app-global-network',
  standalone: true,
  imports: [FormsModule, MemberMapComponent, NgOptimizedImage, NgClass, NgIf, NgForOf],
  templateUrl: './global-network.component.html',
  styleUrls: ['./global-network.component.scss']
})
export class GlobalNetworkComponent implements OnInit {
  // Component Variables
  regionCoordinates: RegionCoordinates = new RegionCoordinates();
  activeFilter = 'Global';
  companyData: ICompanyOverview[] = [];
  mapData: ICompanyOverview[] = [];
  zoom: number = 2.8;
  centerLat: number = 15;
  centerLong: number = 0;
  searchText: string | null = null;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  env = environment;

  constructor(private apiService: ApiService,
              private websiteService: WebsiteService) {
    this.loadMarkers();
  }

  ngOnInit(): void {
    this.loadMarkers();
    if (this.websiteService.CurrentUserLocation) {
      const l = this.websiteService.CurrentUserLocation;
      this.setCenter(l.Latitude, l.Longitude);
      this.zoom = 5;
    }
  }

  ngOnDestroy() {
    this.websiteService.CurrentUserLocation = null;
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadMarkers() {
    this.loading = true;

    const sessionMapData = this.websiteService.NetworkMapData;

    if (sessionMapData && sessionMapData.length > 0) {
      this.companyData = this.getCorporateFilteredList(sessionMapData);
      this.mapData = [...this.companyData];
      this.loading = false;
    } else {
      this.apiService.get('Map/GetData').pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data: ICompanyOverview[]) => {
        if (data) {
          this.companyData = this.getCorporateFilteredList(data);
          this.mapData = [...this.companyData];
          this.websiteService.NetworkMapData = this.mapData;
        }
        this.loading = false;
      });
    }
  }

  noResults() {
    return !this.mapData || this.mapData.length <= 0;
  }

  getCorporateFilteredList(companyData: ICompanyOverview[]) {
    return [...companyData.filter(x => x.Country != 'Russian Federation')]
  }

  searchMembers() {
    const s = this.searchText?.toLowerCase();

    if (s && s.length > 0) {
      const result = this.companyData.filter(x => x.Name.toLowerCase().includes(s) ||
                                                  x.Country.toLowerCase().includes(s) ||
                                                  x.City?.toLowerCase().includes(s));
      if (result && result.length > 0) {
        this.mapData = [...result];
      } else {
        this.mapData = [];
      }
    } else {
      this.resetMap();
      this.searchText = null;
    }
  }

  goToMemberLocation(member: ICompanyOverview) {
    this.setCenter(member?.Latitude, member?.Longitude);
    this.zoom = 1;
    this.zoom = 6;
  }

  resetMap() {
    this.mapData = [...this.companyData];
  }

  getCountryCount() {
    const array = this.companyData;

    if (array && array.length > 0) {
      const unique = [...new Set(array.map(item => item.Country))];
      return unique.length;
    }

    return '-';
  }

  filterRegion(region: string) {
    this.activeFilter = region;

    switch (region) {
      case 'NA':
        this.setCenter(this.regionCoordinates.NorthAmerica.lat, this.regionCoordinates.NorthAmerica.lng);
        this.zoom = 3.5;
        break;
      case 'SA':
        this.setCenter(this.regionCoordinates.SouthAmerica.lat, this.regionCoordinates.SouthAmerica.lng);
        this.zoom = 3.5;
        break;
      case 'ME':
        this.setCenter(this.regionCoordinates.MiddleEast.lat, this.regionCoordinates.MiddleEast.lng);
        this.zoom = 4;
        break;
      case 'AF':
        this.setCenter(this.regionCoordinates.Africa.lat, this.regionCoordinates.Africa.lng);
        this.zoom = 3.5;
        break;
      case 'EU':
        this.setCenter(this.regionCoordinates.Europe.lat, this.regionCoordinates.Europe.lng);
        this.zoom = 4;
        break;
      case 'OC':
        this.setCenter(this.regionCoordinates.Oceania.lat, this.regionCoordinates.Oceania.lng);
        this.zoom = 4;
        break;
      case 'AS':
        this.setCenter(this.regionCoordinates.Asia.lat, this.regionCoordinates.Asia.lng);
        this.zoom = 3;
        break;
      default:
        this.setCenter(15, 0);
        this.zoom = 2.5;
    }
  }

  setCenter(lat: number, long: number) {
    this.centerLat = lat;
    this.centerLong = long;
  }
}
