import {Component, OnDestroy, OnInit} from '@angular/core';
import { IEmployee } from '../../services/models/contact.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { NgForOf } from '@angular/common';
import { EmployeeCardComponent } from './employee-card/employee-card.component';

@Component({
  selector: 'app-team',
  standalone: true,
  templateUrl: 'team.component.html',
  imports: [
    NgForOf,
    EmployeeCardComponent
  ],
  styleUrls: ['team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {
  // Icons

  // Component Variables
  employees: IEmployee[] = [];
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.loadEmployees();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadEmployees() {
    this.loading = true;

    this.apiService.get('Contact/GetEmployeeData').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data) => {
      if (data && data.length > 0) {
        this.employees = Object.assign([] as IEmployee[], data);
      }
      this.loading = false;
    });
  }
}
