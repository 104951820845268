<div class="disclaimer-wrapper">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-12">
          <h1 class="harmony-blue-text fw-bold">
            Disclaimer
          </h1>
        </div>
      </div>
      <br>
      <div class="row mb-5">
        <div class="col-md-12">
          <p class="harmony-grey-text">
            In spite of all our efforts, Harmony cannot guarantee that the information on this website is complete,
            correct and always up-to-date. The same applies to any links that are directly or indirectly connected to
            this website.
            <br>
            Harmony is not responsible for the content of any website that can be visited via any such link.
            Harmony reserves the right to make changes to the information or to add to it at any time without notice.
          </p>
          <p class="harmony-grey-text">
            By using this site, you are not entering into a contractual agreement with Harmony – and neither will this
            ever be the case – for the purpose of requesting information, advice or services of this nature.
            <br>
            Harmony’s renounces any legal liability that may arise from the use of the content and does not provide any
            guarantee regarding the accuracy of the information that it contains or that access to the website shall be
            uninterrupted at all times.
          </p>
          <p class="harmony-grey-text">
            Harmony cannot accept responsibility for special, incidental or severe damage or loss, including lack of
            accessibility, loss of data and revenue, as a result of the use of information that is available on
            this website.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
