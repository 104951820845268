<div class="contact-wrapper">
  <div class="row mt-1">
    <div class="col-12 text-center">
      <h1 class="harmony-purple-text fw-bold">
        How can we help?
      </h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <!-------------------------->
      <!------ Contact Tabs ------>
      <!-------------------------->
      <ul class="nav nav-tabs" id="contact-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link fs-4 harmony-teal-text" [ngClass]="{'active': isHQContact}" id="head-office-tab" data-bs-toggle="tab" href="#head-office" role="tab" aria-controls="head-office" aria-selected="true">
            Head Office
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link fs-4 harmony-teal-text" [ngClass]="{'active': !isHQContact}" id="member-tab" data-bs-toggle="tab" href="#member" role="tab" aria-controls="member" aria-selected="false">
            Members
          </a>
        </li>
      </ul>
      <div class="tab-content" id="contact-tab-content">
        <div class="tab-pane fade show" [ngClass]="{'active': isHQContact}" id="head-office" role="tabpanel" aria-labelledby="head-office-tab">
          <!-------------------------->
          <!------ Head Office ------->
          <!-------------------------->
          <form [formGroup]="headOfficeForm">
            <div class="row mt-4 mb-5">
              <div class="col-lg-4 col-sm-12">
                <div class="hq-contact-card mb-3">
                  <h2 class="harmony-coral-text fw-bold">Contact Info</h2>
                  <h5 class="harmony-sand-text fw-bold">Harmony Relocation B.V.</h5>
                  <p>International Head Office</p>
                  <p>Leeuwenveldseweg 16m</p>
                  <p>1382 LX Weesp <small>(Amsterdam)</small></p>
                  <p>The Netherlands</p>
                  <a href="tel:+31-294-415-012" class="harmony-sand-link">+31 294 415 012</a>
                </div>
              </div>
              <div class="col-lg-5 col-sm-12">
                <div class="row mt-1">
                  <div class="col-12">
                    <select
                      class="form-select" id="select-department"
                      [ngClass]="{'required': headOfficeForm.controls['Department'].touched &&
                                              headOfficeForm.controls['Department'].value['Address'] === ''}"
                      [formControlName]="'Department'">
                      <option [ngValue]="null" [defaultSelected]="true" [disabled]="true">Select a department...</option>
                      <option *ngFor="let dep of departments" [ngValue]="dep">{{ dep.Name }}</option>
                    </select>
                    <app-loader *ngIf="loadingDepartments" [Width]="20"></app-loader>
                  </div>
                </div>
                <hr class="harmony-divider-blue">
                <div class="row mt-2">
                  <div class="col-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Full Name"
                      aria-label="Full Name"
                      [formControlName]="'FullName'"
                      [ngClass]="{'required': headOfficeForm.controls['FullName'].invalid && headOfficeForm.controls['FullName'].touched}"
                      aria-describedby="contact-name">
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <input
                      type="email"
                      [email]="true"
                      class="form-control"
                      placeholder="Email"
                      aria-label="Email"
                      [formControlName]="'Email'"
                      [ngClass]="{'required': headOfficeForm.controls['Email'].invalid && headOfficeForm.controls['Email'].touched}"
                      aria-describedby="contact-email">
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      rows="5"
                      placeholder="Your Message"
                      aria-label="Your Message"
                      [formControlName]="'MessageText'"
                      [ngClass]="{'required': headOfficeForm.controls['MessageText'].invalid && headOfficeForm.controls['MessageText'].touched}"
                      aria-describedby="contact-message"></textarea>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 text-center">
                    <small class="harmony-grey-text"
                           [ngClass]="{'required-text': headOfficeForm.invalid && headOfficeForm.touched}">
                      All fields are mandatory
                    </small>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <button class="btn btn-outline-primary harmony-coral-outline-button w-50"
                      (click)="sendHQContactRequest()">
                      {{ sending ? 'Sending...' : 'Send' }}
                    </button>
                  </div>
                </div>
                <app-loader *ngIf="sending" [Width]="25"></app-loader>
              </div>
              <div class="col-lg-3 col-sm-12">
                <div class="contact-info-card">
                  <div class="row">
                    <div class="col-2 offset-5 col-md-2 offset-md-5 col-lg-4 offset-lg-4 text-center">
                      <img class="w-75" src="../../../assets/icons/color/teal/icon-sm-location-home.svg" alt="contact"/>
                    </div>
                    <div class="col-12 col-lg-12 pt-2">
                      <p>
                        The Harmony International Head Office is based in Amsterdam, The Netherlands, and consists of a small team of dedicated people supporting our Network members and their customers.
                        Please contact us if you have any questions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane fade" [ngClass]="{'active show': !isHQContact}" id="member" role="tabpanel" aria-labelledby="member-tab">
          <!-------------------------->
          <!----- Member Contact ----->
          <!-------------------------->
          <form [formGroup]="memberForm">
            <div class="row mt-4 mb-5">
              <div class="col-lg-4 col-sm-12">
                <img class="proud-member-image" src="../../../assets/images/shaped/long-horizontal-members-1.png" alt="contact"/>
              </div>
              <div class="col-lg-5 col-sm-12">
                <div class="row mt-1">
                  <div class="col-12">
                    <select
                      class="form-select"
                      id="select-member"
                      [ngClass]="{'required': memberForm.controls['Member'].touched &&
                                              memberForm.controls['Member'].value['MemberEmail'] === ''}"
                      [formControlName]="'Member'">
                      <option [ngValue]="null" [defaultSelected]="true" [disabled]="true">Select a member...</option>
                      <option *ngFor="let member of orderedMembers()" [ngValue]="member">{{ member.Name }}</option>
                    </select>
                    <app-loader *ngIf="loadingMembers" [Width]="20"></app-loader>
                  </div>
                </div>
                <hr class="harmony-divider-blue">
                <div class="row mt-2">
                  <div class="col-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Full Name"
                      aria-label="Full Name"
                      [ngClass]="{'required': memberForm.controls['FullName'].invalid && memberForm.controls['FullName'].touched}"
                      [formControlName]="'FullName'"
                      aria-describedby="contact-name">
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <input
                      type="email"
                      [email]="true"
                      class="form-control"
                      placeholder="Email"
                      aria-label="Email"
                      [ngClass]="{'required': memberForm.controls['Email'].invalid && memberForm.controls['Email'].touched}"
                      [formControlName]="'Email'"
                      aria-describedby="contact-email">
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      rows="5"
                      placeholder="Your Message"
                      aria-label="Your Message"
                      [ngClass]="{'required': memberForm.controls['MessageText'].invalid && memberForm.controls['MessageText'].touched}"
                      [formControlName]="'MessageText'"
                      aria-describedby="contact-message"></textarea>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 text-center">
                    <small class="harmony-grey-text"
                      [ngClass]="{'required-text': memberForm.invalid && memberForm.touched}">
                      All fields are mandatory
                    </small>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <button
                      class="btn btn-outline-primary harmony-coral-outline-button w-50"
                      (click)="sendMemberContactRequest()">
                      {{ sending ? 'Sending...' : 'Send' }}
                    </button>
                  </div>
                </div>
                <app-loader *ngIf="sending" [Width]="25"></app-loader>
              </div>
              <div class="col-lg-3 col-sm-12 mt-sm-3">
                <img class="member-image-1" src="../../../assets/images/vector/world-1.svg" alt="contact"/>
                <img class="member-image-2" src="../../../assets/images/vector/network-people-1.svg" alt="contact"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
