import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-people',
  standalone: true,
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
