<div class="portal-redirect-wrapper">
  <div class="row text-center">
    <div class="col-12">
      <h1 class="harmony-blue-text fw-bold">
        The Harmony Portal has moved to a new URL
      </h1>
    </div>
  </div>
  <br>
  <div class="row text-center">
    <div class="col-12">
      <button class="btn btn-primary harmony-coral-button"
              (click)="navigateToPortal()">
        Go to Portal
      </button>
    </div>
  </div>
</div>
