import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, NgClass } from '@angular/common';
import { AutocompleteOffDirective } from '../../directives/autocomplete-off/autocomplete-off.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  imports: [
    AutocompleteOffDirective,
    NgbInputDatepicker,
    NgClass,
    FaIconComponent
  ],
  styleUrls: ['./date-picker.component.scss'],
  providers: [DatePipe]
})
export class DatePickerComponent implements OnInit {
  @Input() set value(value: string) {
    if (value) {
      this.currentValue = this.datePipe.transform(value, this.format);
    } else {
      this.currentValue = null;
    }
  }
  get value(): string | null {
    return this.currentValue;
  }

  @Input() identifier!: string;
  @Input() title = 'Select a date';
  @Input() placeholder = 'dd MMM yyyy';
  @Input() showError = false;
  @Input() maxDate: any;
  @Input() minDate: any;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  faCalendar = faCalendar;

  format = 'dd MMM yyyy';
  currentValue: string | null = null;

  constructor(private datePipe: DatePipe) { }

  ngOnInit() { }

  setDate(event: NgbDate) {
    if (event) {
      const date = new Date(event.year, event.month - 1, event.day);
      this.currentValue = this.datePipe.transform(date, this.format);
      this.onChange.emit(this.currentValue);
    } else {
      this.clear();
    }
  }

  toNgbDate(event: any) {
    if (event.target.value) {
      const date = new Date(event.target.value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1);
      const day = date.getDate();
      this.setDate(new NgbDate(year, month, day));
    } else {
      this.clear();
    }
    return;
  }

  clear() {
    this.currentValue = '';
    this.onChange.emit(null);
  }
}
