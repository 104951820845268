import {Injectable} from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  private static formatErrors(error: any) {
    return throwError(error.error);
  }

  addDefaultHeaders(headers: HttpHeaders) {
    headers = headers.append(environment.api_key_header, environment.api_key);
    return headers;
  }

  get(path: string, headers?: HttpHeaders | null): Observable<any> {
    // Create headers
    if (headers == null){
        headers = new HttpHeaders();
    }
    headers = this.addDefaultHeaders(headers);
    // Create and return request
    return this.http.get(
        `${environment.api_url}${path}`,
        {headers: headers}
    ).pipe(catchError(ApiService.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.addDefaultHeaders(headers);
    // Create and return request
    return this.http.put(
        `${environment.api_url}${path}`,
        body,
        {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.addDefaultHeaders(headers);
    // Create and return request
    return this.http.post(
        `${environment.api_url}${path}`,
        body,
        {headers}
    ).pipe(catchError(ApiService.formatErrors));
  }


  postFile(path: string, body: object = {}): Observable<HttpResponse<Blob>> {
    // Create headers
    let headers = new HttpHeaders();
    headers = this.addDefaultHeaders(headers);
    // Create and return request
    return this.http.post<Blob>(
      `${environment.api_url}${path}`,
      body,
      {headers, observe: 'response', responseType: 'blob' as 'json'}
    ).pipe(catchError(ApiService.formatErrors));
  }

  delete(path: string): Observable<any> {
      // Create headers
      let headers = new HttpHeaders();
      headers = this.addDefaultHeaders(headers);
      // Create and return request
      return this.http.delete(
          `${environment.api_url}${path}`,
          {headers}
      ).pipe(catchError(ApiService.formatErrors));
  }
}
