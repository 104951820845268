import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private alertService: AlertService) { }

  ///////////////////////
  //// Local Storage ////
  ///////////////////////
  storeLocalItem(key: string, obj: any) {
    return localStorage.setItem(key, JSON.stringify(obj));
  }

  getLocalItem(key: string) {
    return JSON.parse(<string>localStorage.getItem(key));
  }

  removeLocalItem(key: string) {
    localStorage.removeItem(key);
  }

  clearAllLocal() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      this.removeLocalItem(<string>key);
    }
  }

  /////////////////////////
  //// Session Storage ////
  /////////////////////////
  storeSessionItem(key: string, obj: any) {
    try {
      sessionStorage.removeItem(key);
      sessionStorage.setItem(key, JSON.stringify(obj));
    } catch {
      this.alertService.info('Could not store User-Token. Session storage is full.');
    }
  }

  getSessionItem(key: string) {
    return JSON.parse(<string>sessionStorage.getItem(key));
  }

  removeSessionItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clearAllSession() {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      this.removeSessionItem(<string>key);
    }
  }

  ////////////////////////
  //// Cookie Storage ////
  ////////////////////////

}
