import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, SearchComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private env = environment;

  constructor(public router: Router) { }

  ngOnInit(): void { }

  goToPortal() {
    window.open(this.env.portal_url, "_blank");
  }
}
