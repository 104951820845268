import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { ApiService } from './services/api.service';
import { WebsiteService } from './services/website.service';
import { IntranetService } from './services/intranet.service';
import { StorageService } from './services/storage.service';
import { Title } from '@angular/platform-browser';
import { CookiePolicyComponent } from './core/cookie-policy/cookie-policy.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ CommonModule, RouterOutlet, FooterComponent, HeaderComponent, HeaderMobileComponent, AlertComponent, CookiePolicyComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    ApiService,
    WebsiteService,
    IntranetService,
    StorageService
  ]
})
export class AppComponent {
  constructor(private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle('Harmony Relocation Network');
  }
}
