import { Component, Input } from '@angular/core';
import { IArticleOverview } from '../../../services/models/articles.model';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DatePipe, NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-article-item',
  standalone: true,
  templateUrl: './article-item.component.html',
  imports: [
    NgStyle,
    NgClass,
    DatePipe,
    NgIf
  ],
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent {
  // Inputs
  @Input()
  set Article(article: IArticleOverview) {
    this.article = article;
  }
  @Input() Class: string | null = null;

  article!: IArticleOverview;

  protected readonly env = environment;
  protected readonly environment = environment;

  constructor(private router: Router) { }


  readArticle() {
    this.router.navigate(['insights/article/', this.article?.Id]).catch();
  }

}
