<div class="row mt-3 mx-0">
  <div class="col-sm-12 offset-sm-0 col-lg-10 offset-lg-1">
    <div class="about-us-wrapper">
      <div class="about-us-content">
        <app-history *ngIf="section === 'history'"></app-history>
        <app-clients *ngIf="section === 'clients'"></app-clients>
        <app-services *ngIf="section === 'services'"></app-services>
        <app-people *ngIf="section === 'people'"></app-people>
        <app-commitment *ngIf="section === 'commitment'"></app-commitment>
      </div>
    </div>
  </div>
</div>
