<div class="team-wrapper">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="harmony-blue-text fw-bold">
        Meet the team
      </h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="employee-card" *ngFor="let employee of employees" >
        <app-employee-card [Employee]="employee"></app-employee-card>
      </div>
    </div>
  </div>
</div>

