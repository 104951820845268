import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import { faDownload, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../environments/environment';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {LoaderComponent} from '../../shared/components/loader/loader.component';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {ArticleService} from "../../services/article.service";
import { IArticle, IArticleAttachment } from '../../services/models/articles.model';
import { FileService } from '../../services/file.service';
import { IApiResponse } from '../../shared/models/api-reponse.model';

@Component({
    selector: 'app-article',
    standalone: true,
  imports: [RouterOutlet, FaIconComponent, LoaderComponent, DatePipe, NgIf, NgClass, NgForOf],
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faNews = faNewspaper;
  protected readonly faDownload = faDownload;

  // Component Variables
  public article: IArticle | null | undefined;
  imageUrl: string = '';
  placeholderImage: string = '';
  attachments: IArticleAttachment[] = [];

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  public loading: boolean = false;
  env = environment;

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private fileService: FileService,
              private articleService: ArticleService) {
  }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.placeholderImage = `${this.env.SiteUrls.AzureStorageBaseURL}${environment.SiteUrls.NewsImagePlaceHolderUrl}`;
    this.route.params.subscribe((params: Params) => {
        this.getArticle(params['id']);
    });
  }

  ngOnDestroy(): void {
      this.unsubscribe.next(null);
      this.unsubscribe.complete();
  }

  getArticle(articleId: number) {
    this.loading = true;
    this.articleService.get(articleId)
      .subscribe({
        next: (data) => {
          this.article = {...data}
          this.setImageUrl();
          this.getAttachments(articleId);
          this.loading = false;
        },
        error: () => {
          this.article = null;
          this.loading = false;
        }
      });
  }

  getAttachments(articleId: number) {
    this.articleService.getAttachments(articleId)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.attachments = [...data];
          this.article!.TotalAttachments = this.attachments?.length ?? 0;
        }, error: (err: IApiResponse) => {
          this.article!.TotalAttachments = 0;
          this.loading = false;
        }
      });
  }

  downloadAttachment(reference: string) {
    this.fileService.GetFile(environment.ContainerNames.ArticleAttachment, reference);
  }

  sanitizeArticleContent(article: string) {
      return this.sanitizer.bypassSecurityTrustHtml(article);
  }

  setImageUrl() {
    if (this.article){
      this.imageUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.ArticleImages}/${this.article.ArticleId}/${this.article.ImageReference}`
    }
  }
}
