export class Alert {
  type: AlertType | undefined;
  message: string | undefined;
  alertId: string | undefined;
  keepAfterRouteChange: boolean | undefined;

  constructor(alert: { message?: string; type?: AlertType; alertId: string | undefined; }) {
    Object.assign(this, alert);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}
