import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-business-solutions',
  standalone: true,
  templateUrl: './business-solutions.component.html',
  imports: [
    RouterLink
  ],
  styleUrls: ['./business-solutions.component.scss']
})
export class BusinessSolutionsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
