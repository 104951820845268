<div class="card contact-card mt-2 mb-2">
  <div class="contact-image-wrapper">
    <img class="masked" [src]="Employee?.ImageUrl ?
           env.SiteUrls.AzureStorageBaseURL + Employee?.ImageUrl :
          'https://www.belizeplanners.org/wp-content/uploads/2016/01/male-placeholder.jpg'" [alt]="Employee?.FullName">
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="card-title harmony-teal-text fw-bold mb-1">{{ Employee?.FullName }}</h4>
        <p class="fst-italic fw-bold harmony-grey-text">{{ Employee?.JobTitle }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <span (click)="contactEmployee(Employee)" [title]="'Contact ' + Employee?.FullName" class="pointer card-link harmony-coral-text">
          <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="card-text">
          {{ Employee?.Bio }}
        </p>
      </div>
    </div>
  </div>
</div>
