import {Injectable, OnDestroy} from '@angular/core';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ICompany, ICompanyOverview } from './models/member.model';
import { IEmployee } from './models/contact.model';
import { ILocation } from './models/member-map.model';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService implements OnDestroy {
  // Icons

  // Service Variables
  NetworkMapData: ICompanyOverview[] = [];
  DirectQuoteCompany!: ICompany;
  CurrentUserLocation: ILocation | null = null;
  EmployeeToContact: IEmployee | null = null;
  MemberToContact: ICompanyOverview | null = null;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private api: ApiService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  GetUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((resp) => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        }, (err) => {
          reject(err);
        });
    });
  }

  public SearchWebsite(searchText: string) {
    const param = {
      SearchText: searchText,
      MembersOnly: true
    };

    return this.api.post('Company/SearchCompanies', param).pipe(
      takeUntil(this.unsubscribe)
    );
  }
}
