<div class="service-wrapper">
  <div class="service-content">
    <div class="row">
      <div class="col-md-12">
        <h1 class="harmony-blue-text fw-bold">
          Our Services
        </h1>
      </div>
    </div>
    <div class="row harmony-grey-text">
      <div class="col-12">
        <p>
          Harmony Relocation Network offers Moving, Relocation and Move Management Services. We understand that our job is
          more than just a service, we move people’s lives.
        </p>
        <p>
          Whatever we do, we do it with our heart and soul. We genuinely care for people, we believe in long-lasting
          relationships, and we will always go the extra mile.
        </p>
        <p>
          You will see this in the people we employ, our dedication to exceed your expectations, the ownership we take
          until the services are completed, the pride we feel when receiving a compliment, the fact that we're always
          available to help our clients, transferees, partners and employees, and the strong reputation we are proud to
          uphold.
        </p>
        <p>
          “In everything we do, you will feel part of our family. We hope you will allow us to be part of yours.”
        </p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="service-type-wrapper">
          <div class="service-type-header text-center">
            <h3 class="harmony-teal-text fw-bold">
              Moving Services
            </h3>
          </div>
          <div class="service-type-content">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <p>
                  Moving services includes everything below and more:
                </p>
                <ul style="list-style-type: circle">
                  <li>
                    <b>Pre-move survey</b>
                  </li>
                  <li>
                    <b>Customs clearance formalities</b>
                  </li>
                  <li>
                    <b>Disassembly and packing of goods</b>
                  </li>
                  <li>
                    <b>Door to door transportation by sea, air or over land</b>
                  </li>
                  <li>
                    <b>Unloading</b>
                  </li>
                  <li>
                    <b>Unpacking</b>
                  </li>
                  <li>
                    <b>Re-assembly of dismantled furniture</b>
                  </li>
                </ul>
              </div>
              <div class="d-sm-none col-md-4 d-md-inline-block">
                <img class="image-moving" src="../../../../assets/images/vector/combo-truck.svg" alt=""/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  Moving also includes supplemental services, such as crating of high value or fragile items, storage, third
                  party services (electrician, carpenter, handyman, cleaning services), exceptional items that require
                  special equipment or manpower (e.g. safes, pool tables, cars, motorcycles, boats etc…).
                </p>
                <p>
                  Our movers will also arrange parking permits, outside elevators, or shuttle services when required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="service-type-wrapper">
          <div class="service-type-header text-center">
            <h3 class="harmony-teal-text fw-bold">
              Relocation Services
            </h3>
          </div>
          <div class="service-type-content">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <p>
                  Our relocation services (or ‘destination services’) ensure that transferees and their families feel at home
                  in their new location in no time.
                </p>
                <p>
                  Relocation services offered by our network include:
                </p>
                <ul style="list-style-type: circle">
                  <li>
                    <b>Area-orientation</b>
                  </li>
                  <li>
                    <b>Home search</b>
                  </li>
                  <li>
                    <b>School search</b>
                  </li>
                  <li>
                    <b>Settling-in assistance</b>
                  </li>
                  <li>
                    <b>Departure services</b>
                  </li>
                </ul>
              </div>
              <div class="d-sm-none col-md-4 d-md-inline-block">
                <img class="image-moving" src="../../../../assets/images/vector/combo-world.svg" alt=""/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  In addition, our network typically offers high-touch services such as Airport collection, Serviced
                  accommodation, Tenancy management, Lease renewal, Concierge services, Car leasing and Visa & immigration
                  services (dependent on location).
                </p>
                <p>
                  Language training, Cross-cultural training, Spousal support and Furniture rental can also be coordinated
                  with our external partners.
                </p>
                <p>
                  If there’s another service that you need, please contact us as this list is not exhaustive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="service-type-wrapper">
          <div class="service-type-header text-center">
            <h3 class="harmony-teal-text fw-bold">
              Move Management
            </h3>
          </div>
          <div class="service-type-content">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <p>
                  Our clients initiate the move, and we select the best moving company for each
                  initiation, based on price and quality. We offer:
                <ul style="list-style-type: circle">
                  <li>
                    <b>Supply chain management</b>
                  </li>
                  <li>
                    <b>Client-facing technology</b>
                  </li>
                  <li>
                    <b>Quality control</b>
                  </li>
                  <li>
                    <b>Central billing</b>
                  </li>
                  <li>
                    <b>Reporting</b>
                  </li>
                </ul>
              </div>
              <div class="d-sm-none col-md-4 d-md-inline-block">
                <img class="image-moving" src="../../../../assets/images/vector/network-people-1.svg" alt=""/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  The movers that are part of the competitive panel can be determined in close cooperation with the customer.
                </p>
                <p>
                  Our innovative technology platforms build bridges between clients, transferees and our network partners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="case-study-wrapper">
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="harmony-coral-text fw-bold">
                Case Study
              </h3>
            </div>
          </div>
          <div class="row harmony-sand-text">
            <div class="col-12">
              <p class="font-italic">
                <b>"</b> The RFP was unlike anything we had seen before…Exciting!
              </p>
              <p class="font-italic">
                The client previously had one provider for global moving services. They wanted something completely different,
                and asked the bidders to submit creative proposals for the best way to manage their programme.
              </p>
              <p class="font-italic">
                The end result was a Move-Management solution, with Harmony Relocation as the main contract holder.
                Multiple Moving Companies in the client’s main markets bid for the work on a job by job basis, using
                market-leading technology to automatically compare bids. 99% of all home-surveys are done using Move4U video
                technology, and 100% of the moves are executed using the Move4U CrewPro technology for inventory management,
                right through to final sign-off at destination. The Move4U system is fully integrated with our back office
                system RedSky Mobility. <b>"</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
