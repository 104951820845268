import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  templateUrl: './confirmation-modal.component.html',
  imports: [
    NgIf
  ],
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  public ConfirmButton: string = 'OK';
  public CancelButton: string = 'Cancel';
  public Message: string = 'Are you sure you want to delete this?';
  @Output() Answer: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {}

  confirm() {
    this.Answer.emit(true);
    this.activeModal.close(true);
  }

  cancel() {
    this.Answer.emit(false);
    this.activeModal.close(false);
  }
}
