import { Component, OnInit } from '@angular/core';
import { faIdBadge, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Router, RouterLink } from '@angular/router';
import { ICompanySearchResult } from '../../services/models/member.model';
import { WebsiteService } from '../../services/website.service';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-search-mobile',
  standalone: true,
  templateUrl: './search-mobile.component.html',
  imports: [
    FormsModule,
    FaIconComponent,
    RouterLink,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./search-mobile.component.scss']
})
export class SearchMobileComponent implements OnInit {
  // Icons
  faSearch = faSearch;
  faNoLogo = faIdBadge;

  // Component Variables
  searchText: string | null = null;
  searchResults: ICompanySearchResult[] = [];
  searchInFocus: boolean = false;

  constructor(public router: Router,
              private websiteService: WebsiteService) { }

  ngOnInit() {
  }

  onPrimaryMobileSearch() {
    if (this.searchText && this.searchText.length > 0) {
      this.websiteService.SearchWebsite(this.searchText).subscribe((results: ICompanySearchResult[]) => {
        if (results && results.length) {
          this.searchResults = Object.assign([], results);
        } else {
          this.searchResults = [];
        }
      });
    } else {
      this.searchResults = [];
    }
  }

  showResults() {
    const hasResults = this.searchResults && this.searchResults.length > 0;
    return hasResults && this.searchInFocus;
  }
}
