<div class="global-network-wrapper">
  <div class="row text-center">
    <div class="col-12">
      <h1 class="harmony-purple-text fw-bold pt-3 pb-1">
        Our Global Network
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1 offset-sm-0">
      <div class="map-nav">
        <div class="row text-center">
          <div class="col-12">
            <ul class="list-group list-group-flush list-group-horizontal-md justify-content-center px-3 harmony-grey-text fw-bold">
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'Global' }">
                <a (click)="filterRegion('Global')">
                  Global
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'NA' }">
                <a (click)="filterRegion('NA')">
                  North America
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'SA' }">
                <a (click)="filterRegion('SA')">
                  South America
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'EU' }">
                <a (click)="filterRegion('EU')">
                  Europe
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'AF' }">
                <a (click)="filterRegion('AF')">
                  Africa
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'ME' }">
                <a (click)="filterRegion('ME')">
                  Middle East
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'AS' }">
                <a (click)="filterRegion('AS')">
                  Asia
                </a>
              </li>
              <li class="list-group-item network-list-item" [ngClass]="{ 'active-item' : activeFilter === 'OC' }">
                <a (click)="filterRegion('OC')">
                  Oceania
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="companyData && companyData.length > 0">
        <div class="col-12 member-map-wrapper">
          <div class="member-map">
            <app-member-map [CompanyData]="mapData"
                            [Zoom]="zoom"
                            [Center]="{ lat: centerLat, lng: centerLong }">
            </app-member-map>
          </div>
          <div class="member-map-search">
            <div class="row">
              <div class="col-12">
                <input
                  class="form-control"
                  type="search"
                  placeholder="Find members..."
                  aria-label="Search"
                  [(ngModel)]="searchText"
                  (input)="searchMembers()">
              </div>
            </div>
            <hr class="harmony-grey-text">
            <div class="member-map-results">
              <div class="row" *ngIf="mapData">
                <div class="col-12">
                  <div class="member-result-row" *ngFor="let member of mapData" (click)="goToMemberLocation(member)">
                    <div class="row result-row pointer" [title]="member.Name">
                      <div class="col-2 align-self-center">
                        <img width="25" height="25" class="float-end"
                             ngSrc="../../../assets/icons/map/map-marker-default.svg" alt="default"
                             *ngIf="!member.IsMovingServiceProvider && !member.IsRelocationServiceProvider">
                        <img width="25" height="25" class="float-end"
                             ngSrc="../../../assets/icons/map/map-marker-default.svg" alt="moving-services"
                             *ngIf="member.IsMovingServiceProvider && !member.IsRelocationServiceProvider">
                        <img width="25" height="25" class="float-end"
                             ngSrc="../../../assets/icons/map/map-marker-relocation.svg" alt="relocation-services"
                             *ngIf="!member.IsMovingServiceProvider && member.IsRelocationServiceProvider">
                        <img width="25" height="25" class="float-end"
                             ngSrc="../../../assets/icons/map/map-marker-all.svg" alt="all-services"
                             *ngIf="member.IsMovingServiceProvider && member.IsRelocationServiceProvider">
                      </div>
                      <div class="col-10 ps-0 overflow-hidden">
                        <p class="result-name">
                          {{ member.Name }}
                        </p>
                        <small class="small">
                          {{ member.Country }}, {{ member.City ?? '-' }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="noResults()">
                <div class="col-12">
                  No results...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 mx-2">
        <div class="col-12 fw-bold">
          <div class="d-inline mx-2 harmony-teal-text">
            <img width="25" height="25"
                 ngSrc="../../../assets/icons/map/map-marker-default.svg"
                 alt="moving-services">
            Moving Services
          </div>
          <div class="d-inline mx-2 harmony-purple-text">
            <img width="25" height="25"
                 ngSrc="../../../assets/icons/map/map-marker-relocation.svg"
                 alt="relocation-services">
            Relocation Services
          </div>
          <div class="d-inline mx-2 harmony-yellow-text">
            <img width="25" height="25"
                 ngSrc="../../../assets/icons/map/map-marker-all.svg"
                 alt="all-services">
            Moving and Relocation Services
          </div>
        </div>
      </div>
      <div class="row text-center mt-1">
        <div class="col-sm-12 col-md-6 position-relative">
          <p class="map-count-header">
            {{ companyData ? companyData.length : '-'}}
          </p>
          <img class="member-count-icon" src="../../../assets/images/vector/combo-truck.svg" alt=""/>
          <p class="map-count-sub-header harmony-teal-text">
            Our global network of local experts
          </p>
        </div>
        <div class="col-sm-12 col-md-6 position-relative">
          <p class="map-count-header">
            {{ getCountryCount() }}
          </p>
          <img class="member-count-icon" src="../../../assets/images/vector/combo-world.svg" alt=""/>
          <p class="map-count-sub-header harmony-teal-text">
            Countries located in
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
