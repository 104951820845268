<div class="policy-wrapper">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-12">
          <h1 class="harmony-blue-text fw-bold">
            Privacy Notice
          </h1>
        </div>
      </div>
      <br>
      <div class="row mb-5">
        <div class="col-md-12">
          <p class="harmony-grey-text">
            In order to create a quotation or to carry out your relocation, we need to collect certain personal
            information concerning yourself, those that accompany you on your relocation, your residence and your
            belongings. This information may be provided by your employer (if your employer is the booker of your
            relocation) or will be requested from yourself.
            The collection and use of your personal data is subject to the EU General Data Protection Regulations (GDPR).
            Harmony Relocation and her network partners have strict rules for the collection, storage, protection and
            release of your personal data:
          </p>
          <ul>
            <li class="harmony-grey-text">
              Any personal information collected will only be used for the purposes of carrying out your relocation and
              (if relevant) related activities and services such as insurance, interactions with authorities etc.;
            </li>
            <li class="harmony-grey-text">
              Personal information will only be shared with those parties or authorities involved in effecting your
              relocation. Harmony Relocation will never sell, trade or share personal information with third parties.
              Harmony Relocation and her network partners have strict privacy policies in place;
            </li>
            <li class="harmony-grey-text">
              Harmony Relocation does not engage in profiling based on personal information. No automated decision making
              is done;
            </li>
            <li class="harmony-grey-text">
              Information may be shared with companies inside or outside the EU where this is necessary to carry out your
              relocation;
            </li>
            <li class="harmony-grey-text">
              Within the EU, Norway, Liechtenstein and Iceland, the GDPR regulations apply; outside the EU, some countries
              are considered to have sufficient protective legislation (Andorra, Argentina, Canada (commercial organisations), Faroe Islands,
              Guernsey, Isle of Man, Israel, Japan, Jersey, New Zealand, South-Korea, Switzerland, United Kingdom and
              Uruguay), all other countries do not meet the GDPR level of privacy protection;
              <ul>
                <li>
                  It may be that the origin or destination of your removal lies in a country that has no proper data
                  protection regime as identified in the GDPR. In that case, Harmony Relocation is legally allowed to
                  share this information with parties involved to ensure the effectuation of your removal;
                </li>
                <li>
                  In any case, our Harmony Relocation network partners around the world are subject to strict internal
                  policies concerning data protection that apply to our international removals;
                </li>
              </ul>
            </li>
            <li class="harmony-grey-text">
              You have the right to access the information gathered by (Harmony Relocation), to demand correction of any
              incorrect information, or to have your personal information removed (within the limits of our legal
              retention). For this you may contact your move coordinator or the contact below;
            </li>
            <li class="harmony-grey-text">
              You have the right to complain to the relevant authorities (<a class="harmony-blue-link fw-bold" href="https://autoriteitpersoonsgegevens.nl/en">autoriteitpersoonsgegevens.nl</a>
              or <a class="harmony-blue-link fw-bold" href="tel:+31(0)9002001201">+31 (0)900 2001201</a>) if you feel your data
              privacy has been dealt with incorrectly.
            </li>
          </ul>
          <p class="harmony-grey-text">
            Please be advised that there are certain countries where shipping information is freely available or can be obtained on the basis of freedom of information.
            If in a specific country shipping or customs information is publicly available this is beyond our control.
            Harmony and its Member companies cannot be responsible for the publication of information which is legally obtained from third parties.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="policy-contact-wrapper">
            <div class="row pt-3">
              <div class="col-12 text-center">
                <h5 class="harmony-teal-text fw-bold">Information Privacy Notice Inquiries</h5>
              </div>
            </div>
            <div class="row">
              <div class="d-none d-md-inline-block col-md-4">
                <img class="contact-image" src="../../../../assets/images/vector/laptop-1.svg" alt="">
              </div>
              <div class="col-sm-12 col-md-8">
                <address class=" harmony-blue-text p-4">
                  <a class="harmony-purple-link fw-bold" [routerLink]="['/member/1']">Harmony Relocation B.V.</a>
                  <br>
                  <small class="fw-bold">Attn. Data Protection Officer</small>
                  <br>
                  <small>Leeuwenveldseweg 16m</small>
                  <br>
                  <small>1382 LX Weesp (Amsterdam)</small>
                  <br>
                  <small>The Netherlands</small>
                  <br>
                  <small>T: <a href="tel:+31-294-415-012" class="harmony-blue-link">+31 294 415 012</a></small>
                  <br/>
                  <a class="btn btn-sm btn-primary harmony-purple-button w-100 mt-3" href="mailto:dpo@harmonyrelo.com">Contact DPO</a>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
