<div class="article-card pointer" (click)="readArticle()" *ngIf="article">
    <div class="article-headline"
         [ngStyle]="{ backgroundImage: article.ImageReference ?
       'url(\'' + environment.SiteUrls.AzureStorageBaseURL + environment.ContainerNames.ArticleImages + '/' + article.Id + '/' + article.ImageReference + '\')' :
       'url(\'https:\/\/hrn-ui.azurewebsites.net/assets/branding/HRN_header_idmanual.jpg\')' }">
        <p class="article-headline-text" [ngClass]="Class">
            {{ article.Title }}
        </p>
    </div>
    <div class="article-body harmony-grey-text">
        <p class="article-publish-date fw-bold">
            {{ article.PublishDate | date: env.FormattingStandards.ShortDateFormat }} -
            <span class="harmony-grey-text fw-light">{{ article.Author ?? '' }}</span>
        </p>
        <p class="article-content mt-2 mb-5">
            {{ article.Summary }} <a class="read-more-link" (click)="readArticle()">Read article</a>
        </p>
    </div>
</div>
