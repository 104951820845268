import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IArticleOverview, IArticleSearchParam } from '../../services/models/articles.model';
import { ICompany, ICompanyDetail, ICompanyOverview } from '../../services/models/member.model';
import { MapStyles } from '../../core/constants/map-styles';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../services/company.service';
import { WebsiteService } from '../../services/website.service';
import { NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MemberMapComponent } from '../../shared/components/member-map/member-map.component';
import { ArticleItemComponent } from '../insights/article-item/article-item.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ArticleService } from "../../services/article.service";
import { IPage, IPaginationData } from "../../shared/models/pagination-data.model";
import { IOrderParam } from "../../shared/directives/order/order.directive";

@Component({
  selector: 'app-member',
  standalone: true,
  templateUrl: './member.component.html',
  imports: [
    NgIf,
    NgStyle,
    MemberMapComponent,
    ArticleItemComponent,
    NgOptimizedImage,
    LoaderComponent
  ],
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  @Input()
  set CompanyId(id: number) {
    if (id && id > 0) {
      this.loadMember(id);
      this.loadLatestArticle(id);
    }
  }

  // Component Variables
  company: ICompany | null = null;
  mapData: ICompanyOverview [] = [];
  companyTelephone: ICompanyDetail | null = null;
  companyUrl: ICompanyDetail | null = null;
  companyEmail: ICompanyDetail | null = null;
  mapStyles = MapStyles;
  article: IArticleOverview | null = null;
  notFound = false;
  placeholderUrl: string = '';
  page: IPage = {
    batched: false,
    pageNumber: 1,
    pageSize: 1
  };
  order: IOrderParam = {
    OrderBy: 'PublishDate',
    OrderDirection: 'desc'
  }

  // General variables
  loading: boolean = false;
  env = environment;

  constructor(private companyService: CompanyService,
              private articleService: ArticleService,
              private websiteService: WebsiteService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.placeholderUrl = this.env.SiteUrls.AzureStorageBaseURL + 'placeholders/large-placeholder-image.jpg';

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.route.params.subscribe((param: Params) => {
        this.loadMember(param['id']);
        this.loadLatestArticle(param['id']);
    });
  }

  loadMember(companyId: number) {
    if (companyId && companyId > 0) {
      this.loading = true;
      this.companyService.getCompany(companyId).subscribe((data: ICompany) => {
        if (data) {
          this.company = Object.assign({}, data);
          this.companyTelephone = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'MemberTelephone');
          this.companyUrl = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'Url');
          this.companyEmail = this.companyService.getCompanyDetailValue(this.company.CompanyDetails, 'MemberEmail');
          this.setMapData();
        } else {
          this.company = null;
          this.mapData = [];
          this.notFound = true;
        }
        this.loading = false;
      });
    }
  }

  getCompanyPicture() {
    if (this.company && this.company.CompanyPicture) {
      return this.company.CompanyPicture;
    } else {
      return this.placeholderUrl;
    }
  }

  getCompanyLogo() {
    if (this.company && this.company.CompanyLogo) {
      return this.company.CompanyLogo;
    } else {
      return this.placeholderUrl;
    }
  }

  loadLatestArticle(companyId: number) {
    if (companyId && companyId > 0) {
      const param = {
        SearchText: null,
        Featured: null,
        Website: true,
        ActiveOnly: true,
        Status: 'Published',
        CompanyId: companyId,
        UserId: null
      } as IArticleSearchParam;

      this.articleService.GetArticles(this.page, this.order, param)
        .subscribe((data: IPaginationData<IArticleOverview>) => {
          if (data?.DataSet && data.DataSet?.length > 0) {
            this.article = {...data.DataSet[0]};
          } else {
            this.article = null;
          }
        });
    }
  }

  openNewWindow(url: string | undefined | null) {
    if (url) {
      const hasHttps = url.includes('https') || url.includes('https');

      if (hasHttps) {
        window.open(url, '_blank');
      } else {
        window.open('https://' + url, '_blank');
      }
    }
  }

  getDirectQuote() {
    this.websiteService.DirectQuoteCompany = <ICompany>this.company;
    this.router.navigate(['direct-quote']).catch();
  }

  contactMember() {
    const member = this.getCompanyOverview();

    if (member) {
      this.websiteService.MemberToContact = member;
      this.router.navigate(['contact']).catch();
    }
  }

  setMapData() {
    const member = this.getCompanyOverview();

    if (member) {
      this.mapData = [];
      this.mapData.push(member);
    }
  }

  getCompanyOverview() {
    const website = this.companyService.getCompanyDetailValue(this.company?.CompanyDetails, 'WebSite');
    const memberEmail = this.companyService.getCompanyDetailValue(this.company?.CompanyDetails, 'MemberEmail');
    const memberTelephone = this.companyService.getCompanyDetailValue(this.company?.CompanyDetails, 'MemberTelephone');

    return {
      CompanyID: this.company?.CompanyId,
      CompanyType: null,
      Name: this.company?.Name,
      Country: this.company?.CountryPhysical,
      Biography: this.company?.Biography,
      CompanyLogo: this.company?.CompanyLogo,
      Latitude: this.company?.Latitude,
      Longitude: this.company?.Longitude,
      WebSite: website ? website.PropertyValue : null,
      MemberEmail: memberEmail ? memberEmail.PropertyValue : null,
      ContactNumber: memberTelephone ? memberTelephone.PropertyValue : null,
      IsMovingServiceProvider: this.company?.IsMovingServiceProvider,
      IsRelocationServiceProvider: this.company?.IsRelocationServiceProvider,
      City: this.company ? this.company.CityPhysical : '-'
    } as ICompanyOverview;
  }
}
