<!-- Menu Component -->
<app-header class="desktop-menu"></app-header>
<app-mobile class="mobile-menu"></app-mobile>
<!-- Cookie Policy -->
<app-cookie-policy></app-cookie-policy>
<!-- Content -->
<div class="content-wrapper">
  <div class="content" role="main">
    <!-- Alerts appear here -->
    <app-alert></app-alert>
    <!-- Router Outlet: This is where the routed component will be generated -->
    <router-outlet></router-outlet>
    <!-- Footer -->
    <app-footer></app-footer>
  </div>
</div>
