import {
  AfterViewChecked, ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ICompanyOverview } from '../../services/models/member.model';
import { MapStyles } from '../../core/constants/map-styles';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { WebsiteService } from '../../services/website.service';
import { EmailService } from '../../services/email.service';
import { environment } from '../../../environments/environment';
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICityDto } from '../../services/models/location.model';
import { IDictionary, IEmailRequest } from '../../services/models/email.model';
import { OpenConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal-functions';
import { DatePickerComponent } from '../../shared/components/date-picker/date-picker.component';
import { CitySearchComponent } from '../../shared/components/city-search/city-search.component';
import { MemberMapComponent } from '../../shared/components/member-map/member-map.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';

@Component({
  selector: 'app-direct-quote',
  standalone: true,
  imports: [RouterOutlet, ReactiveFormsModule, DatePickerComponent, CitySearchComponent, MemberMapComponent, NgOptimizedImage, RouterLink, RecaptchaModule, LoaderComponent, NgClass, NgIf, NgForOf],
  templateUrl: './direct-quote.component.html',
  styleUrls: ['./direct-quote.component.scss']
})
export class DirectQuoteComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('recaptcha', { static: true }) private _recaptchaElement: RecaptchaComponent | undefined;
  @ViewChild('origin', { static: true }) origin: ElementRef | undefined;
  @ViewChild('destination', { static: true }) destination: ElementRef | undefined;

  // Component variables
  quoteRequest!: UntypedFormGroup;
  reCaptchaSiteKey: string;
  members: ICompanyOverview[] | undefined | null;
  filteredMembers: ICompanyOverview[] = [];
  canViewMembers: boolean = false;
  mapStyles = MapStyles;
  dropdownText: string | undefined;
  recaptchaApproved: boolean = false;
  selectedMemberId: number | null = null;
  originLatitude!: number;
  originLongitude!: number;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private router: Router,
              private websiteService: WebsiteService,
              private modalService: NgbModal,
              private emailService: EmailService,
              private cdRef: ChangeDetectorRef) {
    this.reCaptchaSiteKey = environment.reCaptchaSiteKey;
  }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.setDefaultText();

    this.quoteRequest = new UntypedFormGroup({
      AcceptedTerms: new UntypedFormControl(false, Validators.requiredTrue),
      ContactNumber: new UntypedFormControl(null, [Validators.required,
        Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\\s*[)]?[-\\s\\.]?[(]?[0-9]{1,3}[)]?([-\\s\\.]?[0-9]{3})([-\\s\\.]?[0-9]{3,4})'),
        Validators.minLength(10)]),
      Email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      ExpectedMoveDate: new UntypedFormControl(null, Validators.required),
      FirstName: new UntypedFormControl(null, Validators.required),
      LastName: new UntypedFormControl(null, Validators.required),
      Origin: new UntypedFormControl(null, Validators.required),
      Destination: new UntypedFormControl(null, Validators.required),
      QuoteCompany: new UntypedFormControl(null, Validators.required),
      SelectedServices: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      ServiceDescription: new UntypedFormControl(null, Validators.required),
      Title: new UntypedFormControl(null, Validators.required)
    });

    if (this.websiteService.DirectQuoteCompany &&
      this.websiteService.DirectQuoteCompany.Latitude &&
      this.websiteService.DirectQuoteCompany.Longitude) {
      this.originLatitude = this.websiteService.DirectQuoteCompany.Latitude;
      this.originLongitude = this.websiteService.DirectQuoteCompany.Longitude;
      this.loadMembersInArea(<number>this.originLatitude, <number>this.originLongitude, this.websiteService.DirectQuoteCompany.CountryPhysicalIso);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setDefaultText() {
    this.dropdownText = 'Select a quote from one of our members';
  }

  setExpectedMoveDate(date: string | null) {
    this.quoteRequest?.controls['ExpectedMoveDate'].setValue(date);
  }

  getToday(){
    return new Date().toDateString();
  }

  toggleService(service: string) {
    if (this.quoteRequest?.controls['SelectedServices'] && this.quoteRequest.controls['SelectedServices'].value) {
      const contains = this.quoteRequest.controls['SelectedServices'].value.toString().includes(service);
      const multiple = this.quoteRequest.controls['SelectedServices'].value.toString().split(',');

      if (contains) {
        if (multiple.length === 2) {
          const serviceString = this.quoteRequest.controls['SelectedServices'].value.toString().replace(service, '').replace(',', '').trim();
          this.quoteRequest.controls['SelectedServices'].setValue(serviceString);
        } else {
          this.quoteRequest.controls['SelectedServices'].setValue('');
        }
      } else {
        if (multiple.length === 1) {
          multiple.push(service);
          const serviceString = multiple.join(', ');
          this.quoteRequest.controls['SelectedServices'].setValue(serviceString);
        } else {
          this.quoteRequest.controls['SelectedServices'].setValue(service);
        }
      }
    } else {
      this.quoteRequest?.controls['SelectedServices'].setValue(service);
    }
    this.updateMemberList();
  }

  setOrigin(city: ICityDto) {
    if (city == null) {
      this.members = null;
      this.quoteRequest?.controls['Origin'].setValue(null);
      this.canViewMembers = false;
      return;
    }
    this.quoteRequest?.controls['Origin'].setValue(city?.InfoName);

    // Update map
    const coordinates = city.Coordinates.split(',');
    this.originLatitude = parseFloat(coordinates[0].trim());
    this.originLongitude = parseFloat(coordinates[1].trim());
    this.loadMembersInArea(this.originLatitude, this.originLongitude, city.CountryIso);
  }

  setDestination(city: ICityDto | null) {
    this.quoteRequest?.controls['Destination'].setValue(city?.InfoName);
  }

  setMember(member: ICompanyOverview) {
    this.dropdownText = `${member.Name} , ${member.Country}`;
    this.quoteRequest?.controls['QuoteCompany'].markAsTouched();
    this.quoteRequest?.controls['QuoteCompany'].setValue(member);
    this.selectedMemberId = member.CompanyID;
  }

  loadMembersInArea(lat: number, lng: number, countryIso: string) {
    this.loading = true;

    this.apiService.get(`Map/GetDataByLocation/${lat}/${lng}/${countryIso}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((data: ICompanyOverview[]) => {
      if (data && data.length > 0) {
        this.canViewMembers = true;
        if (this.websiteService.DirectQuoteCompany && this.members && this.members.length > 0) {
          const memberToKeep = this.members.find((x) => x.CompanyID === this.websiteService.DirectQuoteCompany.CompanyId);
          this.members = Object.assign([], data);
          const checkMember = this.members.find((x) => x.CompanyID === this.websiteService.DirectQuoteCompany.CompanyId);
          if (memberToKeep && checkMember === null) {
            this.members.push(memberToKeep);
          }
        } else {
          this.members = Object.assign([], data);
        }
        this.filteredMembers = this.members;
        this.updateMemberList();
        this.quoteRequest?.controls['QuoteCompany'].enable();
        this.quoteRequest?.controls['QuoteCompany'].setValue(null);
        this.setDefaultText();
        this.preSelectCompany();
      } else {
        this.alertService.info('Unfortunately we do not have any members in the area.');
      }
      this.loading = false;
    });
  }

  updateMemberList() {
    if (this.members && this.members.length > 0) {
      if (this.quoteRequest?.controls['SelectedServices'].value.toString().includes('Moving Services') &&
        this.quoteRequest.controls['SelectedServices'].value.toString().split(',').length === 1) {
        this.filteredMembers = this.members.filter(x => x.IsMovingServiceProvider);

      } else if (this.quoteRequest?.controls['SelectedServices'].value.toString().includes('Relocation Support / Destination Services') &&
        this.quoteRequest.controls['SelectedServices'].value.toString().split(',').length === 1) {
        this.filteredMembers = this.members.filter(x => x.IsRelocationServiceProvider);
      } else if (this.quoteRequest?.controls['SelectedServices'].value.toString().split(',').length === 2) {
        this.filteredMembers = this.members.filter(x => x.IsRelocationServiceProvider || x.IsMovingServiceProvider);
      }
    }
  }

  preSelectCompany() {
    if (this.websiteService.DirectQuoteCompany) {
      const member = this.members?.find((x) => x.CompanyID === this.websiteService.DirectQuoteCompany.CompanyId);
      if (member) {
        this.setMember(member);
      }
    }
  }

  resolved(captchaResponse: string | null) {
    if (captchaResponse != null) {
      this.loading = true;
      const params = { RecaptchaResponse: captchaResponse };

      this.apiService.post('Contact/ValidateRecaptcha', params).subscribe({
        next: (data) => {
          if (data) {
            this.recaptchaApproved = true;
          }
          this.loading = false;
        }, error: () => {
          this.recaptchaApproved = false;
          this.alertService.error('An error has occurred while trying to verify the user.');
          this.loading = false;
        }
      });
    } else {
      this.recaptchaApproved = false;
    }
  }

  buildEmailRequest() {
    const body: IDictionary<string> = {};
    body['CompanyName'] = this.quoteRequest?.controls['QuoteCompany'].value.Name;
    body['SelectedServices'] = this.quoteRequest?.controls['SelectedServices'].value;
    body['Description'] = this.quoteRequest?.controls['ServiceDescription'].value;
    body['Title'] = this.quoteRequest?.controls['Title'].value;
    body['Email'] = this.quoteRequest?.controls['Email'].value;
    body['FirstName'] = this.quoteRequest?.controls['FirstName'].value;
    body['LastName'] = this.quoteRequest?.controls['LastName'].value;
    body['ContactNumber'] = this.quoteRequest?.controls['ContactNumber'].value;
    body['ExpectedMoveDate'] = this.quoteRequest?.controls['ExpectedMoveDate'].value;
    body['Origin'] = this.quoteRequest?.controls['Origin'].value;
    body['Destination'] = this.quoteRequest?.controls['Destination'].value;

    return {
      Subject: `Quote Request via Harmony Relocation Network - ${this.quoteRequest?.controls['FirstName'].value} ${this.quoteRequest?.controls['LastName'].value}`,
      ToAddress: this.quoteRequest?.controls['QuoteCompany'].value.MemberEmail, // change this when testing
      Template: 'Direct Quote',
      ScheduleID: null,
      Body: body,
      Attachments: null
    } as IEmailRequest;
  }

  sendQuoteRequest() {
    this.quoteRequest?.markAllAsTouched();
    this.quoteRequest?.markAsTouched();

    if (this.quoteRequest?.valid && this.recaptchaApproved) {
      this.loading = true;
      const contact = this.quoteRequest?.controls['ContactNumber'].value;
      const company: ICompanyOverview = this.quoteRequest.controls['QuoteCompany'].value;
      this.quoteRequest.controls['ContactNumber'].setValue(contact.toString());

      const email = this.buildEmailRequest();

      this.emailService.sendEmail(email).subscribe({
        next: (data) => {
          if (data) {
            const message = "Quote request successfully sent to '" + company.Name + "'" +
              "\n\n You will be contacted by them within 2 working days." +
              "\nYou will be redirected to the member's page.";
            OpenConfirmationModal(this.modalService, message, 'OK', '')
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(() => {
                if (this.websiteService.DirectQuoteCompany) {
                  this.router.navigate(['/member/' + this.websiteService.DirectQuoteCompany.CompanyId]).catch();
                } else {
                  this.router.navigate(['/member/' + this.selectedMemberId]).catch();
                }
              });
          }
          this.loading = false;
        }, error: () => {
          this.alertService.error('An error has occurred while trying to send the Direct Quote request. Please try again later.');
          this.loading = false;
        }
      });
    }
  }
}
