import { Component, OnInit } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RouterLink } from '@angular/router';
import { SearchMobileComponent } from '../search-mobile/search-mobile.component';

@Component({
  selector: 'app-mobile',
  standalone: true,
  templateUrl: './header-mobile.component.html',
  imports: [
    RouterLink,
    SearchMobileComponent
  ],
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent { }
