import { Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { GlobalNetworkComponent } from './components/global-network/global-network.component';
import { InsightsComponent } from './components/insights/insights.component';
import { ArticleComponent } from './components/article/article.component';
import { JoiningOurNetworkComponent } from './components/joining-our-network/joining-our-network.component';
import { ContactComponent } from './components/contact/contact.component';
import { MemberComponent } from './components/member/member.component';
import { DirectQuoteComponent } from './components/direct-quote/direct-quote.component';
import { TeamComponent } from './components/team/team.component';
import { CookiesPolicyComponent } from './components/policies/cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './components/policies/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './components/policies/disclaimer/disclaimer.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PortalRedirectComponent } from './components/portal-redirect/portal-redirect.component';
import { BusinessSolutionsComponent } from './components/business-solutions/business-solutions.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomepageComponent
  },
  {
    path: 'portal',
    component: PortalRedirectComponent
  },
  {
    path: 'portal',
    redirectTo: '/portal',
    pathMatch: 'prefix',
  },
  {
    path: 'about-us/:section',
    component: AboutUsComponent
  },
  {
    path: 'business-solutions',
    component: BusinessSolutionsComponent
  },
  {
    path: 'global-network',
    component: GlobalNetworkComponent
  },
  {
    path: 'insights',
    component: InsightsComponent
  },
  {
    path: 'insights/article/:id',
    component: ArticleComponent
  },
  {
    path: 'joining-our-network',
    component: JoiningOurNetworkComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'contact/:companyID',
    component: ContactComponent
  },
  {
    path: 'member/:id',
    component: MemberComponent
  },
  {
    path: 'direct-quote',
    component: DirectQuoteComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'policies',
    children: [
      {
        path: 'cookies',
        component: CookiesPolicyComponent
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'disclaimer',
        component: DisclaimerComponent
      }
    ]
  },
  // Generic routes
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
