<div class="form-inline search-bar pull-right">
  <input
    class="form-control form-control-sm me-sm-2 search-input"
    type="search"
    placeholder="Search members..."
    aria-label="Search"
    [(ngModel)]="searchText"
    (mouseenter)="searchInFocus = true"
    (input)="onPrimarySearch()">
  <fa-icon class="fa-pull-left pointer search-icon" [size]="'sm'" [icon]="faSearch"></fa-icon>
  <div class="search-results" *ngIf="showResults()" (mouseleave)="searchInFocus = false">
    <a class="text-decoration-none" *ngFor="let result of searchResults" [routerLink]="['member', result.CompanyId]">
      <div class="row search-result-row pointer" [title]="result.Name">
        <div class="col-3 text-center">
          <img *ngIf="result.CompanyLogo" [src]="result.CompanyLogo" [alt]="null" class="search-result-logo">
          <img *ngIf="!result.CompanyLogo" [src]="env.SiteUrls.AzureStorageBaseURL + 'branding/HRN_member_logo_RGB.png'" class="search-result-logo" alt="proud-member-logo">
        </div>
        <div class="col-9 overflow-hidden">
          <p class="search-result-name">
            {{ result.Name }}
          </p>
          <small class="harmony-grey-text small">
            {{ result.Country }}, {{ result.City ?? '-' }}
          </small>
        </div>
      </div>
    </a>
  </div>
</div>
