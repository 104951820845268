<div class="people-wrapper">
  <div class="people-content">
    <div class="row">
      <div class="col-md-12">
        <h1 class="harmony-blue-text fw-bold">
          Our People
        </h1>
      </div>
    </div>
    <div class="row harmony-grey-text">
      <div class="col-md-12">
        <p>
          Our people are central to the services we provide for our clients. We don’t just move household goods or find a
          place to live. We create a happy and enjoyable transition for our assignees to  life in a new culture.
          That is why our people are our most precious resource. The right people, working closely together with each other
          and with our customers, make the difference between a good relocation and a successful new start in a new country.
          Our members are family owned companies with high levels of staff retention, and our clients recognise that
          difference when working with us.
        </p>
        <p>
          We are proud of our heritage and local knowledge. We cherish our craftsmanship, skills and knowledge, and we keep
          passionately mastering it. We love our roots.
        </p>
        <p>
          We move people around the world. Places might be different, but the people we move are always in the same capable
          hands. Our local professionals will take care of them and make them feel at home.
        </p>
        <p>
          When dealing with a Harmony member company you will recognise the difference in:
        </p>
        <ul>
          <li>
            <span class="harmony-blue-text fw-bold">Our Service</span>, always with a personal touch.
          </li>
          <li>
            <span class="harmony-blue-text fw-bold">Our knowledge</span> of local culture, rules, areas, both at origin as well as at destination.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="people-images">
    <img class="people-image image-1" src="../../../../assets/images/shaped/diamond-family-1.png" alt=""/>
    <img class="people-image image-2" src="../../../../assets/images/shaped/diamond-coffee-1.png" alt=""/>
    <img class="people-image image-3" src="../../../../assets/images/shaped/diamond-people-3.png" alt=""/>
  </div>
</div>
