<div class="business-solutions-wrapper">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-12">
          <h1 class="harmony-blue-text fw-bold">
            Business Solutions
          </h1>
        </div>
      </div>
      <div class="row harmony-grey-text">
        <div class="col-12">
          <p>
            The clients of our network members are private individuals, corporate businesses, governments, NGOs and Relocation Management Companies.
          </p>
          <p>
            These parties normally contract with a Harmony member company directly. Our member will use the support services of Harmony Relocation to offer the best possible solution.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="solution-type-wrapper">
            <div class="solution-type-header text-center">
              <h3 class="harmony-teal-text fw-bold">
                Direct Corporate Agreements
              </h3>
            </div>
            <div class="solution-type-content">
              <div class="row">
                <div class="col-12">
                  <p>
                    It is also possible for large global customers to contract directly with Harmony Relocation for global household goods moving and/or destination (relocation) services.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <p class="text-decoration-underline">
                    What Harmony offers:
                  </p>
                  <ul style="list-style-type: circle">
                    <li>
                      <b>Web based relocation technology with role-based access</b>
                    </li>
                    <li>
                      <b>A team of experts, either centrally or ‘tri-regional’</b>
                    </li>
                    <li>
                      <b>A global network of the best possible service providers for moving and/or destination (relocation) services</b>
                    </li>
                    <li>
                      <b>A sustainability programme</b>
                    </li>
                    <li>
                      <b>Financial stability</b>
                    </li>
                    <li>
                      <b>Professional Compliance Programme:</b>
                      <ul style="list-style-type: disc">
                        <li>
                          Mandatory training on Anti Bribery & Corruption, Anti Cartel, GDPR
                        </li>
                        <li>
                          ISO 9001 group certification
                        </li>
                        <li>
                          ISO 14001 group certification
                        </li>
                        <li>
                          ISO 45001 group certification
                        </li>
                        <li>
                          Covid-19 Protocol
                        </li>
                        <li>
                          GDPR & Data security
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Consultancy on best practices</b>
                    </li>
                  </ul>
                </div>
                <div class="d-sm-none col-md-4 d-md-inline-block">
                  <img class="image-moving" src="../../../assets/images/vector/handshake-shape-1.svg" alt=""/>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>
                    All of the above is also available when contracting with a member company directly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
