import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IArticleOverview, IArticleSearchParam } from '../../services/models/articles.model';
import { IntranetService } from '../../services/intranet.service';
import { environment } from '../../../environments/environment';
import { NgForOf, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { GlobalNetworkComponent } from '../global-network/global-network.component';
import { ArticleService } from "../../services/article.service";
import { IPage, IPaginationData } from "../../shared/models/pagination-data.model";
import { IOrderParam } from "../../shared/directives/order/order.directive";

@Component({
  selector: 'app-homepage',
  standalone: true,
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    NgOptimizedImage,
    NgStyle,
    GlobalNetworkComponent
  ],
  providers: [IntranetService]
})
export class HomepageComponent implements OnInit {
  @ViewChild('videoPlayer', {static: true}) videoPlayer: ElementRef | undefined;

  // Component Variables
  videoIndex: number = 0;
  videos: any[] = [
      'webvideos/carousel-video-1.mp4',
      'webvideos/carousel-video-2.mp4',
      'webvideos/carousel-video-3.mp4',
      'webvideos/carousel-video-4.mp4'
  ];
  loadingArticle: boolean = false;
  latestArticles: IArticleOverview[] = [];
  COVIDDocumentUrl: string;
  baseImageUrl = `${environment.SiteUrls.AzureStorageBaseURL}webimages/`;
  page: IPage = {
    batched: false,
    pageNumber: 1,
    pageSize: 15
  };
  order: IOrderParam = {
    OrderBy: 'PublishDate',
    OrderDirection: 'desc'
  }

  // General variables
  protected readonly environment = environment;

  constructor(private articleService: ArticleService) {
    this.COVIDDocumentUrl = `${environment.SiteUrls.AzureStorageBaseURL}${environment.ContainerNames.WebDocuments}/${environment.Blobs.CovidPolicy}`;
  }

  ngOnInit() {
    this.videoIndex = this.randomIntFromInterval(0, this.videos.length - 1);
    this.getLatestArticle();
  }

  nextVideo() {
    const videoCount = this.videos.length;

    if (this.videoIndex === (videoCount - 1)) {
        this.videoIndex = 0;
    } else {
        this.videoIndex++;
    }
  }

  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getLatestArticle() {
    this.loadingArticle = true;

    const param = {
      SearchText: null,
      Featured: null,
      Website: true,
      ActiveOnly: true,
      Status: 'Published',
      CompanyId: 1,
      UserId: null
    } as IArticleSearchParam;

    this.articleService.GetArticles(this.page, this.order, param)
      .subscribe((data: IPaginationData<IArticleOverview>) => {
        if (data && data.DataSet) {
          this.latestArticles = [...data.DataSet];
        }

        this.loadingArticle = false;
      });
  }
}
