import { Component, OnInit } from '@angular/core';
import {
  faAward,
  faBook,
  faHelmetSafety,
  faSeedling
} from '@fortawesome/free-solid-svg-icons';
import { RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-commitment',
  standalone: true,
  imports: [RouterOutlet, FaIconComponent],
  templateUrl: './commitment.component.html',
  styleUrls: ['./commitment.component.scss']
})
export class CommitmentComponent implements OnInit {
  // Icons
  faQuality = faAward;
  faEnvironment = faSeedling;
  faHealthSafety = faHelmetSafety;
  faCode = faBook;

  // Component Variables
  CodeOfConductDocumentUrl: string;
  public env = environment;

  constructor() {
    this.CodeOfConductDocumentUrl = `${this.env.SiteUrls.AzureStorageBaseURL}${this.env.ContainerNames.WebDocuments}/${this.env.Blobs.CodeOfConduct}`;
  }

  ngOnInit() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
